<script setup lang="ts">
import { IconClose } from "@/components/atoms/Icon";
import { useSafeI18n } from "@/composables/useSafeI18n";
import { ref, defineProps, watch, computed } from "vue";

const props = defineProps<{
  count: number;
}>();

const countComputed = computed(() => props.count);

const { t } = useSafeI18n();

let closeTimer: ReturnType<typeof setTimeout> | null = null;
const isOpenWishlistNotification = ref(false);
const wishlistNotificationMessage = ref("");

const handleWishlistItemsChange = (newValue: number, oldValue: number) => {
  if (newValue !== oldValue) {
    isOpenWishlistNotification.value = true;

    newValue > oldValue
      ? (wishlistNotificationMessage.value = t("wishlist.addedToWishlist"))
      : (wishlistNotificationMessage.value = t("wishlist.removedToWishlist"));

    closeTimer = setTimeout(() => {
      isOpenWishlistNotification.value = false;
    }, 3000);
  }
};

const clearTimer = () => {
  if (closeTimer) {
    clearTimeout(closeTimer);
    closeTimer = null;
  }
};

watch(countComputed, handleWishlistItemsChange);
</script>

<template>
  <Teleport to="body">
    <Transition
      enter-active-class="transition ease-out duration-200 transform"
      name="slide-up"
    >
      <div
        v-if="isOpenWishlistNotification"
        class="fixed bottom-0 right-0 z-10 bg-white md:max-w-[31rem] w-full h-auto shadow-xl"
        :data-testid="`whislist-notification`"
        @click="clearTimer"
      >
        <div
          class="flex justify-between font-medium px-4 md:px-6 py-6 text-m-lg md:text-lg"
        >
          {{ wishlistNotificationMessage }}
          <IconClose
            class="w-6 h-6 cursor-pointer"
            @click="isOpenWishlistNotification = false"
          />
        </div>
      </div>
    </Transition>
  </Teleport>
</template>
